import Vue from 'vue';
import Vuex from 'vuex';
Vue.use(Vuex);
import axios from "axios";
import apiClient from './services/apiClient.js';

var axiosLogin = axios.create({
    headers: {
        common: {
            'Content-Type': 'application/json'
        },
    },
});

const state = () => ({
    currency: 'EUR',
    prices: [],
    user: {},
    portfolio: [],
    coins: [],
    coinValues: [],
    screenshots: [],
    dollarPrice: 0
});

const actions = {
    fetchCoin({ commit }, coin_id) {
        apiClient.get(`/coin/${coin_id}`)
            .then(({ data }) => {
                commit("SET_COIN", data);
            });
    },
    fetchCoins({ commit }) {
        apiClient.get('/coin')
            .then(({ data: { data: coins }}) => {
                commit("SET_COINS", coins);
            });
    },
    setCurrency({ commit }, currency) {
        commit("SET_CURRENCY", currency);
    },
    fetchPrices({ commit }) {
        apiClient.get('/prices')
            .then(({ data }) => {
                commit("SET_PRICES", data);
                if(data[0]) {
                    let { quotes: { EUR, USD } } = data[0];
                    commit("SET_DOLLAR", USD / EUR);

                }
            });
    },
    fetchUser({ commit }) {
        apiClient.get('/user')
            .then(({ data, status }) => {
                if(status === 200) commit('SET_USER', data);
            });
    },
    fetchScreenshots({ commit }) {
        apiClient.get('/screenshots')
            .then(({ data }) => {
                commit('SET_SCREENSHOTS', data);
            });
    },
    fetchScreenshotsBetween({ commit }, { start, end}) {
        apiClient.get(`/screenshots/${start}/${end}`)
            .then(({ data }) => {
                commit('SET_SCREENSHOTS', data);
            });
    },
    initApi() {
        axiosLogin.get('/sanctum/csrf-cookie')
            .then(() => {
                axiosLogin.post('/login');
            });
    },
    saveHolding({ commit }, payload) {
        return apiClient.post('/portfolio', payload)
            .then(({ data, status }) => {
                if(status === 200) commit('SET_PORTFOLIO', data);
            })
            .then(resp => {
                return resp;
            });
    },
    fetchPortfolio({ commit }) {
        apiClient.get('/portfolio')
            .then(({ data }) => {
                commit('SET_PORTFOLIO', data);
            });
    },
    updateHolding({ commit }, payload) {
        apiClient.put('/portfolio', payload)
            .then(({ data }) => {
                commit('SET_PORTFOLIO', data);
            });
    }
};

const mutations = {
    SET_DOLLAR(state, price) {
        state.dollarPrice = price;
    },
    SET_COIN(state, coin) {
        state.coinValues.push(coin);
    },
    SET_CURRENCY(state, currency) {
        state.currency = currency;
    },
    SET_PRICES(state, prices) {
        state.prices = prices;
    },
    SET_COINS(state, coins) {
        state.coins = coins;
    },
    SET_USER(state, user) {
        state.user = user;
    },
    SET_PORTFOLIO(state, portfolio) {
        state.portfolio = portfolio;
    },
    SET_SCREENSHOTS(state, screenshots) {
        state.screenshots = screenshots;
    }
};

const getters = {
    getCoinById: (state) => (id) => {
        return state.coinValues[id]
    }
}

export default new Vuex.Store({
    state,
    actions,
    mutations,
    getters
});