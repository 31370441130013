import axios from "axios";

// axios.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest';

const client = axios.create({
    baseURL: process.env.MIX_API_BASE_URL,
    withCredentials: true,
    headers: {
        'Content-Type': 'application/json'
    }
});

export default client;
