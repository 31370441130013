<template>
    <div>
        <span>{{ coinData.rank }}</span>
        <span>{{ coinData.symbol }}</span>
        <span>{{ coinData.name }}</span>
        <span class="text-right">{{ currencySymbol }} {{ price.toFixed(2) }}</span>
        <input type="number" class="text-right" :value="amount" @change="update" />
        <span class="text-right">{{ currencySymbol }} <span class="coin-total">{{ total }}</span></span>
    </div>
</template>

<script>
    import { mapActions, mapState } from 'vuex';
    import { debounce } from './../helpers.js';

    export default {
        name: 'Hold',
        props: {
            'coin': { required: true },
        },
        mounted() {
            this.update = debounce(this.update, 1000);
        },
        computed: {
            ...mapState(['prices', 'currency']),
            id() {
                return this.coin.coin_id;
            },
            amount() {
                return +this.coin.amount || 0;
            },
            currencySymbol() {
                if(this.currency === 'EUR') return '€';
                if(this.currency === 'USD') return '$';
                return '';
            },
            price() {
                const { quotes: { [this.currency]: { price }}} = this.coinData;
                return price || 0;
            },
            total() {
                return (this.price * this.amount).toFixed(2);
            },
            coinData() {
                return this.prices.find(({id}) => id === this.id) || {
                    quotes: {
                        EUR: { price: 0 },
                        USD: { price: 0 },
                    }
                };
            }
        },
        methods: {
            ...mapActions(['updateHolding']),
            update(event) {
                this.updateHolding({ id: this.coin.id, amount: +event.target.value });
            }
        }
    }
</script>

<style lang="css" scoped>
    div {
        grid-template-columns: 60px 60px auto 120px 120px 120px;
        display: grid;
        width: 100%;
    }

    .text-right {
        text-align: right;
    }
</style>