<template>
    <table>
        <tr>
            <th>#</th>
            <th>X</th>
            <th>%</th>
            <th>Sell amount</th>
            <th>Remaining</th>
            <th>$/€</th>
            <th align="right">Profit</th>
            <th align="right">Cummulative</th>
        </tr>
        <SellRow
            v-for="({ price, amount, amountRemain, cum, factor, percentage }, i) in rows"
            :key="`row-${i}`"
            :index="i"
            :value="price"
            :amount="amount"
            :amountRemain="amountRemain"
            :factor="factor"
            :percentage="percentage"
            :cum="cum"
            ref="tableRows" />
        <tr>
            <td align="center" colspan=7>
                <button @click="exportTableToCSV">Download</button>
            </td>
        </tr>
    </table>
</template>

<script>
    import SellRow from './SellRow.vue';
    export default {
        props: ['rows'],
        components: { SellRow },
        methods: {
            downloadCSV(csv, filename) {
                let csvFile;
                let downloadLink;

                csvFile = new Blob([csv], {type: "text/csv"});
                downloadLink = document.createElement("a");
                downloadLink.download = filename;
                downloadLink.href = window.URL.createObjectURL(csvFile);
                downloadLink.style.display = "none";
                document.body.appendChild(downloadLink);
                downloadLink.click();
            },
            exportTableToCSV() {
                let csv = this.rows.map(({ price, amount, amountRemain, cum, factor, percentage }) => {
                    return [factor, percentage, amount, amountRemain, price, amount*price, cum].join(',');
                })
                csv.unshift("X,Percentage %,Sell amount,Remaining,Sell price,Profit,Cummulative")
                this.downloadCSV(csv.join("\n"), `selling-plan-${Date.now()}.csv`);
            }
        }
    }
</script>

<style lang="scss" scoped>
    table {
        font-family: monospace;
        font-size: 1.2vw;
        width: 100%;
        max-width: 60vw;
        margin: 0 auto;
    }
    th {
        font-weight: bold;
        background: lighten(#000, 10%);
        color: #fff;
    }

    tr >>> td {
        padding: 2px 10px;
    }

    tr:nth-child(even) {
        background: lighten(#000, 98%);
    }
    button {
        padding: 8px 15px;
        border-radius: 30px;
        border: none;
        background-color: rgb(50,200,40);
        cursor: pointer;
        color: #fff;
        font-weight: 900;

        &:hover {
            background-color: rgba(50,200,40,0.8);
        }

        &:focus {
            outline: none;
        }
    }
</style>