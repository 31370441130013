import Vue from 'vue'
import Vuex from 'vuex'
import App from './App.vue'
import Coins from './components/Coins.vue';
import SellPlanner from './components/SellPlanner.vue';
import store from './store.js';

Vue.use(Vuex);
Vue.config.productionTip = false

Vue.component('coins', Coins);
Vue.component('sell', SellPlanner);

new Vue({
  render: h => h(App),
  store
}).$mount('#app')
