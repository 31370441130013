<template>
    <canvas ref="chart" />
</template>

<script>
    import Chart from 'chart.js';
    import moment from 'moment';
    import { mapActions, mapState } from 'vuex';
    export default {
        mounted() {
            this.fetchScreenshots();
            this.chart = new Chart(this.$refs.chart, {
                type: 'bar',
                data: {},
                // options: {
                //     scales: {
                //         xAxes: [{
                //             type: 'time',
                //             time: {
                //                 unit: 'month'
                //             }
                //         }],
                //         yAxes: [{
                //             stacked: false
                //         }]
                //     }
                // }
                options: {
                    scales: {
                        xAxes: [{
                            stacked: true
                        }],
                        yAxes: [{
                            stacked: true
                        }],
                    },
                    legend: {
                        display: true,
                        position: 'right',
                        align: 'start',
                    }
                }
            });
        },
        watch: {
            'chartData': 'updateChart'
        },
        computed: {
            ...mapState(['screenshots', 'currency']),
            chartData() {
                let temp = this.screenshots[Object.keys(this.screenshots)[0]] || [];
                return {
                    labels: temp.map(t => this.parseDate(t.updated_at)),
                    datasets: this.datasets
                }
            },
            datasets() {
                /*

                The money amounts should be linked to the time. 
                Right now it just fills it up to the left. 

                */
                return Object.keys(this.screenshots).map(label => {
                    let data = this.screenshots[label].map(ss => this.currency === 'USD' ? +ss.total : +ss.total_eur);
                    return {
                        label,
                        data,
                        backgroundColor: `rgba(${this.getColor(label)}, 1)`,
                        borderColor: `rgba(${this.getColor(label)}, 1)`,
                        borderWidth: 1,
                    }
                });
            }
        },
        methods: {
            ...mapActions(['fetchScreenshots']),
            updateChart() {
                this.chart.data = this.chartData;
                this.chart.update();
            },
            parseDate(string) {
                return moment(string, moment.ISO_8601).format('DD-MM-YY HH:mm')
            },
            getColor(id) {
                let color = this.colors[id] || [55, 55, 55];
                return color.join(',');
            }
        },
        data() {
            return {
                chart: {},
                colors: {
                    'eth-ethereum': [134, 134, 134],
                    'btc-bitcoin': [255, 202, 53],
                    'xlm-stellar': [55, 171, 255],
                    'neo-neo': [0, 187, 23],
                    'dnt-district0x': [55, 55, 55],
                    'bat-basic-attention-token': [55, 55, 55],
                    'gas-gas': [5, 155, 25],
                    '1st-firstblood': [255, 0, 0],
                    'omg-omisego': [55, 155, 55],
                    'miota-iota': [55, 55, 155],
                    'ont-ontology': [155, 55, 55],
                    'gnt-golem': [55, 55, 255]
                }
            }
        }
    }
</script>

<style lang="scss" scoped>
    canvas {
        width: 100%;
        height: 50vh;
    }
</style>