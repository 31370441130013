<template>
    <tr>
        <td class="step" v-text="index+1" />
        <td class="share" v-text="`${factor.toFixed(2)}X`" />
        <td class="percentage" v-text="`${percentage.toFixed(1)} %`" />
        <td class="sell-amount" v-text="amount.toFixed(2)" />
        <td class="amount" v-text="amountRemain.toFixed(2)" />
        <td class="price" align="right" v-text="value.toFixed(2)" />
        <td class="profit" align="right" v-text="profit" />
        <td class="sum" align="right" v-text="formatMoney(cum, 2, '.', '')" />
    </tr>
</template>

<script>
    import { formatMoney } from './../helpers';
    export default {
        props: {
            index: {
                required: true,
                type: Number
            },
            value: {
                required: true,
                type: Number
            },
            amount: {
                required: true,
                type: Number
            },
            amountRemain: {
                required: true,
                type: Number
            },
            factor: {
                required: true,
                type: Number
            },
            percentage: {
                required: true,
                type: Number
            },
            cum: {
                required: true,
                type: Number
            }
        },
        computed: {
            profit() {
                return this.formatMoney((this.value * this.amount), 2, ".", ",");
            }
        },
        methods: {
            formatMoney,
        }
    }
</script>

<style lang="scss" scoped>

    td:nth-child(2) {
        width: 80px;
    }

</style>