<template>
  <div id="app">
    <div class="container">
        <div class="row justify-content-center">
            <div class="col-md-12">
                <div class="card">
                    <div class="card-body">
                        <Sell />
                    </div>
                </div>
            </div>
        </div>
    </div>
  </div>
</template>

<script>
  import Sell from './components/SellPlanner.vue'
export default {
  name: 'App',
  components: {
    Sell
  }
}
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  margin-top: 10px;
}
</style>
