var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('table',[_vm._m(0),_vm._l((_vm.rows),function(ref,i){
var price = ref.price;
var amount = ref.amount;
var amountRemain = ref.amountRemain;
var cum = ref.cum;
var factor = ref.factor;
var percentage = ref.percentage;
return _c('SellRow',{key:("row-" + i),ref:"tableRows",refInFor:true,attrs:{"index":i,"value":price,"amount":amount,"amountRemain":amountRemain,"factor":factor,"percentage":percentage,"cum":cum}})}),_c('tr',[_c('td',{attrs:{"align":"center","colspan":"7"}},[_c('button',{on:{"click":_vm.exportTableToCSV}},[_vm._v("Download")])])])],2)}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('tr',[_c('th',[_vm._v("#")]),_c('th',[_vm._v("X")]),_c('th',[_vm._v("%")]),_c('th',[_vm._v("Sell amount")]),_c('th',[_vm._v("Remaining")]),_c('th',[_vm._v("$/€")]),_c('th',{attrs:{"align":"right"}},[_vm._v("Profit")]),_c('th',{attrs:{"align":"right"}},[_vm._v("Cummulative")])])}]

export { render, staticRenderFns }