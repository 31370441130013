<template>
    <div>
        <input type="date" v-model="start">
        <input type="date" v-model="end">
        <button @click="fetchData">fetch</button>
    </div>
</template>

<script>
    import { mapActions } from 'vuex';
    export default {
        methods: {
            ...mapActions(['fetchScreenshotsBetween']),
            fetchData() {
                console.log(this.start, this.end)
                this.fetchScreenshotsBetween({ start: this.start, end: this.end });
            }
        },
        data() {
            return {
                start: null,
                end: new Date(Date.now()).toISOString().split('T')[0]
            }
        }
    }
</script>