<template>
    <div class="container">
        <Controls @input="updateChart" v-model="input" />
        <div>
            <canvas id="chart" />
            <SellPricesTable :rows="rows" />
        </div>
    </div>
</template>

<script>
    import Chart from 'chart.js';
    import { mapState } from 'vuex';
    import SellPricesTable from './SellPricesTable.vue';
    import Controls from './Controls.vue';
    import { formatMoney } from './../helpers';
    export default {
        name: 'Sell',
        components: { Controls, SellPricesTable },
        watch: {
            sellOrders: 'updateChart',
        },
        mounted() {
            const storage = window.localStorage.getItem('controls_data');
            if(storage) this.input = {...JSON.parse(storage)};

            this.setTotal();
            let chart = document.getElementById('chart');
            this.ctx = chart.getContext('2d');
            this.myLineChart = new Chart(this.ctx, this.config);
            this.updateChart();
        },
        computed: {
            ...mapState(['dollarPrice']),
            sellOrders() {
                const sellingPrices = this.getSellingPrices();
                let { steps, factor, holdings } = this.input;
                let i = 0;
                let orders = [];
                while(i < steps) {
                    let amount = (i * factor + 1) * (holdings / this.triangleNumber)
                    orders[i] = {
                        amount,
                        value: amount * sellingPrices[i]
                    }
                    i++;
                }
                return orders;
            },
            triangleNumber() {
                let { steps, factor } = this.input;
                let cnt = 0;
                for (var i = 1; i <= steps ; i++) {
                    let share = (i - 1) * factor + 1;
                    cnt += share;
                }
                return cnt;
            },
            rows() {
                const sellingPrices = this.getSellingPrices();
                let holdings = this.input.holdings;

                return sellingPrices.map((price, i) => {
                    let amount = this.sellOrders[i].amount;
                    let amountRemain = holdings - amount;
                    holdings -= amount;
                    let sumup = this.sellOrders.slice(0,i+1);
                    let factor = amount / this.sellOrders[0].amount;
                    let percentage = amount / this.input.holdings * 100;
                    let cum = sumup
                        .map(({amount}, z) => amount * sellingPrices[z])
                        .reduce((a,b) => a+b)
                    return {
                        price,
                        amount,
                        amountRemain,
                        factor,
                        percentage,
                        cum
                    }
                })
            },
            totalProfit() {
                let profit = this.rows
                    .map(({amount, sell}) => amount * sell)
                    .reduce((a, b) => a+b);
                return this.formatMoney(profit.toFixed(2), 2, ",", " ");
            },
        },
        methods: {
            formatMoney,
            factorialize(num) {
                var cnt = 1;
                for (var i = 1; i <= num ; i++) {
                    cnt *= i;
                }
                return cnt;
            },
            setTotal() {
                this.total = this.$refs.tableRows
                    ? this.$refs.tableRows
                        .map(i => i.profit)
                        .reduce((a, b) => a + b)
                    : 0;
            },
            getSellingPrices() {
                const stepArray = [];
                let steps = this.input.steps;
                let i = 0;

                if(this.input.log === true) {

                    const minLogVal = Math.log(+this.input.min) / Math.log(10);
                    const maxLogVal = Math.log(+this.input.max) / Math.log(10);
                    const range = maxLogVal - minLogVal;
                    const stepSize = range / (steps - 1);
                    while(i < steps) {
                        let value = Math.pow(10, minLogVal + stepSize * i);
                        stepArray[i] = value;
                        i++;
                    }

                } else {

                    const range = this.input.max - this.input.min;
                    const stepSize = range / (steps - 1);
                    while(i < steps) {
                        stepArray[i] = +this.input.min + stepSize * i;
                        i++;
                    }

                }

                return stepArray;
            },
            getStepLabels() {
                const labels = [];
                let i = 0;
                while(i < this.input.steps) {
                    i++;
                    labels.push(i);
                }
                return labels;
            },
            updateChart() {
                this.myLineChart.data.labels = this.getStepLabels();
                let data = this.getSellingPrices();
                let sum = 0;
                let dataCum = this.sellOrders.map(({ value }) => sum += value);
                this.myLineChart.data.datasets = [
                    {
                        label: 'Sell Price',
                        data,
                        backgroundColor: 'rgba(255,204,0,1)',
                        borderColor: 'rgba(255,204,0,0.5)',
                        fill: false,
                        pointRadius: 5,
                        pointHoverRadius: 10,
                    },
                    {
                        label: 'Cummulative',
                        steppedLine: true,
                        data: dataCum,
                        backgroundColor: 'rgba(255,204,0,0.1)',
                        borderColor: 'rgba(255,204,0,1)',
                        fill: true,
                        yAxisID: 'y-axis-3'
                    }
                ]
                this.myLineChart.update();
            }
        },
        data() {
            return {
                total: 0,
                ctx: null,
                myLineChart: null,
                input: {
                    log: true,
                    min: 500,
                    max: 5000,
                    steps: 4,
                    holdings: 22,
                    factor: 0.25
                },
                config: {
                    type: 'line',
                    data: {
                        labels: [],
                        datasets: []
                    },
                    options: {
                        animation: false,
                        responsive: true,
                        legend: {
                            position: 'bottom',
                        },
                        hover: {
                            mode: 'index'
                        },
                        scales: {
                            xAxes: [{
                                display: true,
                                scaleLabel: {
                                    display: true,
                                    labelString: 'Sell steps'
                                }
                            }],
                            yAxes: [{
                                display: true,
                                scaleLabel: {
                                    display: true,
                                    labelString: 'Sell Price Linear'
                                },
                                type: 'linear',
                                id: 'y-axis-1',
                            }, {
                                display: true,
                                position: 'right',
                                scaleLabel: {
                                    display: true,
                                    labelString: 'Sell Price'
                                },
                                type: 'linear',
                                id: 'y-axis-3',
                            }]
                        },
                        title: {
                            display: true,
                            text: 'Sell plan'
                        }
                    }
                }
            }
        }
    }
</script>

<style lang="scss" scoped>
    .container {
        display: grid;
        grid-template-columns: 200px 1fr;
        grid-gap:50px;
        max-width: 95%;
        margin: 20px auto;
    }
    input {
        width: 100%;
    }
    #chart {
        max-width: 800px;
        margin: 0 auto;
        width: 500px;
        display: block;
        height: 50vh;
        min-height: 200px;
    }
</style>
