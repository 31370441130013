<template>
    <div>
        <span class="controls">
            <button @click="setCurrency('USD')">$ USD</button>
            <button @click="setCurrency('EUR')">€ EUR</button>
        </span>
        <div v-for="hold in portfolio" :key="hold.coin_id" ref="portfolio">
            <Hold :coin="hold" />
        </div>
        <div class="total">{{ currencySymbol }} {{ total.toFixed(2) }}</div>
        <AddHold />
        <button @click="fetchPrices">prices</button>
    </div>
</template>

<script>
    import { mapState, mapActions } from 'vuex';
    import AddHold from './AddHold.vue';
    import Hold from './Hold.vue';
    export default {
        name: 'Portfolio',
        components: { Hold, AddHold },
        watch: {
            prices: 'updateTotals'
        },
        computed: {
            ...mapState(['portfolio', 'prices', 'currency']),
            currencySymbol() {
                if(this.currency === 'EUR') return '€';
                if(this.currency === 'USD') return '$';
                return '';
            },
        },
        mounted() {
            this.fetchPortfolio();
            this.fetchPrices();
        },
        methods: {
            ...mapActions([
                'fetchPrices',
                'fetchPortfolio',
                'setCurrency',
            ]),
            updateTotals() {
                this.total = this.portfolio
                    .map((coin) => {
                        let data = this.prices.find(c => c.id === coin.coin_id);
                        return data.quotes[this.currency].price * coin.amount || 0;
                    })
                    .reduce((a, b) => +a + +b);
            }
        },
        data() {
            return {
                total: 0
            }
        }
    }
</script>

<style lang="scss" scoped>
.controls {
    display: flex;
}
.total {
    text-align: right;
    font-weight: 900;
}
</style>