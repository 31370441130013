<template>
    <form>
        <select v-model="coin_id">
            <option
                :value="coin.id"
                v-for="coin in coins"
                :key="coin.id">
                {{ coin.name }}
            </option>
        </select>
        <input type="number" v-model="amount">
        <button @click="save">save</button>
    </form>
</template>

<script>
    import { mapState, mapActions } from 'vuex';
    export default {
        name: 'AddHold',
        computed: {
            ...mapState(['coins']),
        },
        methods: {
            ...mapActions([
                'saveHolding',
                'fetchCoins'
            ]),
            save() {
                this.saveHolding({
                    coin_id: this.coin_id,
                    amount: this.amount
                });
            }
        },
        mounted() {
            this.fetchCoins();
        },
        data () {
            return {
                coin_id: null,
                amount: 0
            }
        }
    }
</script>

<style lang="css" scoped>
</style>