<template>  
    <div id="controls">
        Start sell<br>
        <input
            v-model="min" 
            type="number"
            step="100">
        Max sell<br>
        <input
            v-model="max" 
            type="number"
            step="100">
        Steps<br>
        <input
            v-model="steps" 
            type="number">
        Holdings<br>
        <input
            v-model="holdings" 
            type="number">
        Factor ({{factor}})<br>
        <input 
            v-model="factor"
            type="range"
            min="0"
            max="1"
            step="0.05">
        <br>
        <input type="checkbox" v-model="log"> Logarithmic sell
    </div>
</template>

<script>
    export default {
        props: ['value'],
        // watch: {
        //     pik(old) {
        //         console.log(old, new)
        //     },
        //     // value: {
        //     //     immediate: true,
        //     //     handle: (config) => {
        //     //         console.log('update', config)
        //     //         // { min, max, steps, holdings, factor }
        //     //         // this.min = min;
        //     //         // this.max = max;
        //     //         // this.steps = steps;
        //     //         // this.holdings = holdings;
        //     //         // this.factor = factor;
        //     //     }
        //     // }
        // },
        computed: {
            min: {
                get() { return this.value.min; },
                set(val) { this.update('min', val); }
            },
            max: {
                get() { return this.value.max; },
                set(val) { this.update('max', val); }
            },
            steps: {
                get() { return this.value.steps; },
                set(val) { this.update('steps', val); }
            },
            holdings: {
                get() { return this.value.holdings; },
                set(val) { this.update('holdings', val); }
            },
            factor: {
                get() { return this.value.factor; },
                set(val) { this.update('factor', val); }
            },
            log: {
                get() { return this.value.log; },
                set(val) { this.update('log', val); }
            },
        },
        mounted() {

        },
        methods: {
            update(key, value) {
                const data = {...this.value, [key]: value }
                window.localStorage.setItem('controls_data', JSON.stringify(data));
                this.$emit('input', data);
            }
        },
        data() {
            return {
                // min: 10,
                // max: 100,
                // steps: 10,
                // holdings: 10,
                // factor: 10,
            }
        }
    }
</script>

<style lang="scss" scoped>
    $color: 'red';
    #controls {
        text-align: left
    }
    input {
        font-family: monospace;

        &[type="number"],
        &[type="range"] {
            width: 100%;
            margin-bottom: 20px;

            &:focus {
                outline: none;
                background: #fff;
            }
        }

    }
    input[type="number"] {
        padding: 8px 15px;
        text-align: right;
        border-radius: 30px;
        border: 1px solid lighten(#000, 95%);
        background: lighten(#000, 95%);
    }

</style>