<template>
    <div>
        <h1>This is coins</h1>
        <DateControls />
        <Chart />
        <Portfolio />
    </div>
</template>

<script>
    import { mapActions } from 'vuex';
    import Portfolio from './Portfolio.vue';
    import Chart from './Chart.vue';
    import DateControls from './DateControls.vue';
    export default {
        name: 'Coins',
        components: { Portfolio, Chart, DateControls },
        beforeMount() {
            this.initApi();
        },
        methods: {
            ...mapActions([
                'initApi'
            ]),
        }
    }
</script>

<style lang="css" scoped>
</style>
